const
    HowToPlayPage = async () => import(
        /* webpackChunkName: 'faq' */
        '../views/HowToPlay.vue'
    );

export default [
    { path: '/how-to-play', component: HowToPlayPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },
];
