
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    IneligibleAgePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligibleAgePage.vue'
    ),
    IneligiblePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligiblePage.vue'
    ),
    LimiterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LimiterPage.vue'
    ),
    LoginPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LoginPage.vue'
    ),
    RegisterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegisterPage.vue'
    ),
    AuthTokenPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/AuthTokenPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            console.log('logged in');

            if (store.state.profile.is_limited) {
                return '/limiter';
            }
            else {
                return '/game';
            }
        }
        // Default routes for returning users (reg cookie) who are not logged-in.
        else if (store.getters['profile/returning']) {
            return '/ineligible';
        }

        // Default route for a user we know nothing about.
        return '/ineligible';
    } },
    { path: '/login', component: LoginPage, meta: { public: true } },
    { path: '/register', component: RegisterPage, meta: { public: true } },
    { path: '/limiter', component: LimiterPage },
    { path: '/ineligible_age', component: IneligibleAgePage, meta: { public: true } },
    { path: '/ineligible', component: IneligiblePage, meta: { public: true } },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
    { path: '/auth_token', component: AuthTokenPage, meta: { public: true } },
];
