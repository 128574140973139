
    import { mapState, mapGetters } from 'vuex';
    import eventBus from '@public/js/eventBus';
    import BaseModal from './BaseModal';

    export default {
        name: 'CompletedChallengesModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'completed-challenges',
                isModalShown: false,
            };
        },
        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),

            ...mapGetters({
                loggedIn: 'profile/loggedIn',
                isSessionExpired: 'profile/isSessionExpired',
                inPhaseQ2: 'app/inPhaseQ2',
                inPhaseQ3: 'app/inPhaseQ3',
                inPhaseQ324: 'app/inPhaseQ324',
                inPhaseQ424: 'app/inPhaseQ424',
                inPhaseG524: 'app/inPhaseG524',
            }),
        },

        mounted () {
            eventBus.$on('modal-opened', this.modalOpened);
        },

        beforeDestroy () {
            eventBus.$off('modal-opened', this.modalOpened);
        },

        methods: {
            modalOpened (modalName) {
                if (modalName === 'completed-challenges') {
                    this.isModalShown = true;
                }
            },

            backToGame () {
                eventBus.$emit('reset-game');
                this.closeModal();

                // Save the scroll target to localStorage for page reload
                localStorage.setItem('scrollTo', '#iw_game_container');

                // Refresh the page
                window.location.reload();
            },
        },

        i18nOptions: { namespaces: ['modal_content', 'buttons'] },
    };
