
    import BaseModal from './BaseModal';

    export default {
        name: 'LandingModal',

        extends: BaseModal,

        props: {
            autoplay: {
                type: Boolean,
                default: true,
            },
            loop: {
                type: Boolean,
                default: true,
            },
        },

        data () {
            return {
                modalName: 'landing',
                videoStarted: false,
            };
        },

        methods: {
            play () {
                // aims to solve a bug where videos
                // intermittently render as "black boxes"
                // on iOS in Safari.
                setTimeout(() => {
                    this.$refs.video?.play();
                }, 0);
            },
            startVideo () {
                if (this.videoStarted) return;
                this.videoStarted = true;
                this.$refs.video?.play();
            },
            pause () {
                this.$refs.video?.pause();
            },
            animationEnded () {
                this.$emit('animation-ended');
            },
        },

        i18nOptions: { namespaces: ['landing', 'buttons'] },
    };
