
    import { mapState, mapGetters } from 'vuex';
    import eventBus from '@public/js/eventBus';
    import BaseModal from './BaseModal';

    export default {
        name: 'InkyModal',


        extends: BaseModal,

        data () {
            return {
                modalName: 'inky',
                uuid: '',
                isModalShown: false,
            };
        },
        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
                isSessionExpired: 'profile/isSessionExpired',
                inPhaseQ2: 'app/inPhaseQ2',
                inPhaseQ3: 'app/inPhaseQ3',
                inPhaseQ324: 'app/inPhaseQ324',
                inPhaseQ424: 'app/inPhaseQ424',
                inPhaseG524: 'app/inPhaseG524',
            }),

            getPrizeName () {
                console.log(this.profile);
                console.log(this.profile.prize_won);
                console.log(this.profile.prize_won.id.replace(/-/gi, ''));
                const prizeUuid = this.profile.prize_won.id.replace(/-/gi, '');
                return this.$t(`prize_name.${prizeUuid}`);
            },

            getPrizeCopy () {
                const prizeUuid = this.profile.prize_won.id.replace(/-/gi, '');
                return this.$t(`prize_copy.${prizeUuid}`);
            },
            getPrizeCopy2 () {
                const prizeUuid = this.profile.prize_won.id.replace(/-/gi, '');
                return this.$t(`prize_copy_2.${prizeUuid}`);
            },
        },

        mounted () {
            eventBus.$on('modal-opened', this.modalOpened);

            eventBus.$on('start-peg-animations', this.startPegAnimations);

            this.uuid = this.profile.prize_won.id.replace(/-/gi, '');
        },

        beforeDestroy () {
            eventBus.$off('modal-opened', this.modalOpened);

            eventBus.$off('start-peg-animations', this.startPegAnimations);
        },

        methods: {
            backToGame () {
                eventBus.$emit('reset-game');
                this.closeModal();

                // Save the scroll target to localStorage for page reload
                localStorage.setItem('scrollTo', '#iw_game_container');

                // Refresh the page
                window.location.reload();
            },

            modalOpened (modalName) {
                if (modalName === 'inky') {
                    this.isModalShown = true;
                    setTimeout(() => {
                        this.startPegAnimations();
                    }, 50);  // Delay of 50ms, adjust as needed
                }
            },

            startPegAnimations () {
                console.log('StartPegAnimations is being called');

                const pegImages = this.$el.querySelectorAll('.peg-img');
                console.log('Peg images:', pegImages);
                pegImages.forEach((pegImage, index) => {
                    pegImage.classList.add('animating');
                    pegImage.style.animationDelay = `${index * 0.75}s`;
                });
            },
        },

        i18nOptions: { namespaces: ['inky', 'buttons'] },
    };
