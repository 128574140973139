
    import { OutgoingAffiliate } from '@roc/affiliate';
    import BasePage from './BasePage';

    export default {
        name: 'ErrorPage',

        components: {
            OutgoingAffiliate,
        },

        extends: BasePage,

        data () {
            return {
                pageName: 'error',
                showSignInButton: false,
                queryParams: '',
            };
        },
        i18nOptions: { namespaces: ['error'] },
        mounted () {
            this.checkQueryParams();
        },
        methods: {
            checkQueryParams () {
                const url = window.location.href;
                const queryString = url.includes('?') ? url.split('?')[1].split('#')[0] : '';
                const urlParams = new URLSearchParams(queryString);

                // Debugging statement to check if the parameters are being read correctly
                console.log('Full URL:', url);
                console.log('Extracted Query String:', queryString);
                console.log('URL Parameters:', urlParams.toString());

                // Check if 'error' is equal to 'token'
                const errorParam = urlParams.get('error');
                console.log('Error Parameter:', errorParam);

                if (errorParam === 'token') {
                    this.showSignInButton = true;
                    // The condition is true, so you can handle the 'error=token' case here
                    const copy = `
                        <p>It appears you need to sign up for Cracker Barrel Rewards to participate.
                        If you already have an account, please re-sign in to the web or app to get back on track.</p>
                        <br>
                        <p>Please see the Official Rules below for complete details on eligibility.</p>`;
                    this.queryParams = copy; // Assign copy to queryParams
                }
                else {
                    const copy = `
                        <p>An error has occurred. Please refresh your web browser or re-sign into to the app to get you back on track.</p>`;
                    this.queryParams = copy; // Assign copy to queryParams
                }
            },
        },
    };
