// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    ComingSoonPage = async () => import(
        /* webpackChunkName: 'coming-soon' */
        '../views/ComingSoonPage.vue'
    );

export default [
    { path: '/coming-soon', alias: '/', component: ComingSoonPage, meta: {
        public: true,
        activeInPhase: ['q4_24_prelaunch', 'g5_24_prelaunch'],
    } },
];
