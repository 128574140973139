
// Asychronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    OamoePage = async () => import(
        /* webpackChunkName: 'oamoe' */
        '../views/OamoePage.vue'
    ),
    AmoePage = async () => import(
        /* webpackChunkName: 'amoe' */
        '../views/AmoePage.vue'
    ),
    OamoeSuccessPage = async () => import(
        /* webpackChunkName: 'oamoe_success' */
        '../views/OamoeSuccessPage.vue'
    ),
    OamoeLimiterPage = async () => import(
        /* webpackChunkName: 'oamoe_limiter' */
        '../views/OamoeLimiterPage.vue'
    );

export default [
    { path: '/oamoe', component: OamoePage, meta: { public: true } },
    { path: '/amoe', component: AmoePage, meta: { public: true } },
    { path: '/oamoe_success', component: OamoeSuccessPage, meta: { public: true }  },
    { path: '/oamoe_limiter', component: OamoeLimiterPage, meta: { public: true }  },
];
