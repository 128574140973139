
    import { mapState, mapGetters, mapActions } from 'vuex';
    // import LoadingScreen from '@components/LoadingScreen.vue';
    // import TheFooter from '@components/TheFooter.vue';
    import { CookieGate } from '@public/vendor/cookie-gate';
    import openModal, { SessionExpirationModal } from './modals';
    import logClientError from './client-error';
    import webAnalytics from './plugins/WebAnalytics';


    export default {
        name: 'App',

        components: {
            CookieGate,
            // LoadingScreen,
            // TheFooter,
        },

        i18nOptions: { namespaces: ['global', 'footer'] },

        provide: {
            $webAnalytics: webAnalytics,
        },

        data () {
            return {
                webAnalyticsReady: false,
                isInApp: false,
                isLoading: true,
            };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
                isSessionExpired: 'profile/isSessionExpired',
                inPhaseQ2: 'app/inPhaseQ2',
                inPhaseQ3: 'app/inPhaseQ3',
                inPhaseQ324: 'app/inPhaseQ324',
                inPhaseQ424: 'app/inPhaseQ424',
                inPhaseQ424ComingSoon: 'app/inPhaseQ424ComingSoon',
                inPhaseG524: 'app/inPhaseG524',
                inPhaseG524ComingSoon: 'app/inPhaseG524ComingSoon',
            }),

        },

        watch: {
            isSessionExpired (value) {
                if (value) {
                    this.openSessionExpirationModal();
                }
            },

            '$route': {
                immediate: true,
                handler () {
                    this.detectPlatform();
                },
            },
        },

        mounted () {
            this.detectPlatform();

            document.body.classList.add(this.app.phase);

            if (this.app.phase === 'q3_2023') {
                document.body.classList.add('phase_q3');
            }
            else if (this.app.phase === 'q3_24') {
                document.body.classList.add('phase_q3_24');
            }
            else if (this.app.phase === 'q4_24' || this.app.phase === 'q4_24_prelaunch') {
                document.body.classList.add('phase_q4_24');
            }
            else if (this.app.phase === 'g5_24' || this.app.phase === 'g5_24_prelaunch') {
                document.body.classList.add('phase_g5_24');
            }
            // Focus the h1 element to force screen readers back to the top after a navigation.
            // This will ensure screen readers will read the page again.
            // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
            this.$router.afterEach(() => this.$refs.h1.focus());

            window.addEventListener('beforeunload', this.startLoading);
            this.$router.beforeEach((to, from, next) => {
                this.startLoading();
                next();
            });
            this.$router.afterEach(() => {
                this.stopLoading();
            });
        },

        beforeDestroy () {
            window.removeEventListener('beforeunload', this.startLoading);
        },

        // If a fatal error occurs rendering any of our descendent components, try
        // to navigate to the error page to preserve some UX.
        // https://vuejs.org/v2/api/#errorCaptured
        errorCaptured (err) {
            this.$router.push('/error');
            // Forward fatal Vue component errors, i.e. those that caused us to display
            // the error page here, to './client-error' and its server-side logging.
            logClientError(err, { fatal: true });
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            async openSessionExpirationModal () {
                await openModal(SessionExpirationModal);

                // After the modal is closed.
                this.logOut();
                this.$router.replace('/');
            },

            getQueryParameter (name) {
                name = name.replace(/\[/, '\\[').replace(/\]/, '\\]');
                const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                const results = regex.exec(location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            },

            detectPlatform () {
                const { platform } = this.$route.query;
                if (platform === 'iOS' || platform === 'Android') {
                    this.isInApp = true;
                    console.log('isInApp', this.isInApp);
                    console.log('platform', platform);
                }
            },

            startLoading () {
                this.isLoading = true;
            },

            stopLoading () {
                this.isLoading = false;
            },
        },
    };
