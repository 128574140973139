
    import eventBus from '@public/js/eventBus';
    import BaseModal from './BaseModal';

    export default {
        name: 'ChallengeModal',

        extends: BaseModal,

        props: {
            challenge: {
                type: String,
                required: true,
            },
        },

        data () {
            return {
                modalName: 'challenge',
            };
        },

        computed: {
            weekNumber () {
                // Extract the week number from the challenge string
                const match = this.challenge.match(/Week (\d+)/i);
                return match ? parseInt(match[1], 10) : null;
            },

            imageSrc () {
                // Convert challenge to lower case and replace spaces with underscores
                const formattedChallenge = this.challenge.toLowerCase().replace(' ', '_');
                return `public/img/challenge_board/hero_${formattedChallenge}.png`;
            },
        },

        mounted () {
            eventBus.$on('modal-opened', this.modalOpened);
        },

        beforeDestroy () {
            eventBus.$off('modal-opened', this.modalOpened);
        },

        i18nOptions: { namespaces: ['challenge', 'buttons'] },
    };
